import React from 'react'
import './Blog.css'

const Blog = () => {
  return (
    <section className="b-container">
      <div className="blog">
        <div className="cards">
          <img src="./lap.png" alt="" />
          <span>Surface Laptop 5</span>
          <span>Sophisticated style and multitasking speed powered by 12th Gen Intel® Core, with Windows 11.</span>
          <a href="">Learn more</a>
        </div>

        <div className="cards">
          <img src="./game.png" alt="" />
          <span>Xbox Series S</span>
          <span>Next-gen performance in the smallest Xbox ever.</span>
          <a href="">Shop Xbox Series S</a>
        </div>

        <div className="cards">
          <img src="./lap.png" alt="" />
          <span>Announcing the next
            wave of Al innovation</span>
          <span>The new Microsoft Bing and Edge will soon
            feature more visual search responses, chat
            history, and persistent chat within Edge.
            We're also adding actions to help people
            complete tasks.</span>
          <a href="">Learn more about it</a>
        </div>

        <div className="cards">
          <img src="./movie.png" alt="" />
          <span>Xbox Game Pass
            Ultimate</span>
          <span>Xbox Live Gold and hundreds of high-
            quality console and PC games. Play together
            with friends and discover your next favourite
            game.</span>
          <a href="">Join now</a>
        </div>
      </div>
    </section>
  )
}

export default Blog