import React from 'react'
import './Business.css'
const Business = () => {
    return (
        <section className="b-container">
            {/* <h2>For business</h2> */}
            <div className="blog">
                <div className="cards">
                    <img src="./lap.png" alt="" />
                    <span>Surface for Business</span>
                    <span>No matter what you do, there’s a Surface to help you do it.</span>
                    <a href="">Shop Now</a>
                </div>

                <div className="cards">
                    <img src="./game.png" alt="" />
                    <span>Get Microsoft Teams for free</span>
                    <span>Online meetings, chat and shared cloud storage – all in one place.</span>
                    <a href="">Sign up for free</a>
                </div>

                <div className="cards">
                    <img src="./lap.png" alt="" />
                    <span>Windows 11 for <br />business</span>
                    <span>Designed for hybrid work. Powerful for employees. Consistent for IT. Secure for all.</span>
                    <a href="">Learn more</a>
                </div>

                <div className="cards">
                    <img src="./movie.png" alt="" />
                    <span>Try Microsoft 365 for free</span>
                    <span>Get Microsoft Teams, secure cloud storage and premium apps across devices with a free one-month Microsoft 365 Business Standard trial.
                    </span>
                    <a href="">Start your free trial</a>
                </div>
            </div>
        </section>
    )
}

export default Business