import React from 'react'
import './Product.css'
const Product = () => {
  return (
    <section className='p-container'> 
        <div className="logos">
        <div className="block">
            <img src="./windows.png" alt="" width={40}/>
            <span>Choose your Microsoft 365</span>
        </div>

        <div className="block">
            <img src="./device.png" alt="" width={40} />
            <span>Explore Surface devices</span>
        </div>

        <div className="block">
            <img src="./games.png" alt="" width={40} />
            <span>Buy Xbox games</span>
        </div>

        <div className="block">
            <img src="./windows.png" alt="" width={40}/>
            <span>Get Windows 11</span>
        </div>
        
    </div>
    </section>
    
  )
}

export default Product