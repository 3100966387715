import React, { useState } from 'react'
import './Header.css'
const Header = () => {
  const [active, setActive] = useState("right-nav")

  const navToggle = () =>{
    active ==='right-nav' ? setActive('right-nav nav_active') : setActive("right-nav")
  }
  return (
    <section className="wrapper">
        <div className="h-container">
       
       {/* left-side */}
       <div className="left-side">
       <img src="./logo.png" alt="" width={100} />
       <div className="tag">
           <a href="">Microsoft 365 </a>
           <a href="">Teams</a>
           <a href="">Windows</a>
           <a href="">Surface</a>
           <a href="">Xbox</a>
           <a href="">Support</a>
       </div>
       </div>

        {/* right-side */}
        <div  className="right-side">
          <div onClick={navToggle} className="ham" >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className={active}>
            <span>All Mircosoft</span>
            <span>Search</span>
            <span>Cart</span>
            <span>Sign in</span>
          </div>
           
       </div>  

   </div>
    </section>

    
    
  )
}

export default Header