import './App.css';
import Blog from './Components/Blogs/Blog';
import Business from './Components/Business/Business';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Slide from './Components/Slide/Slide';
import Design from './Components/design/Design';
import Product from './Components/product/Product';
import Social from './Components/social/Social';

function App() {
  
  return (
    <div className="App">
      <Header/>
      <Slide/>
      <Product/>
      <Blog/>
      <Design/>
      <Business/>
      <Social/>
      <Footer/>
    </div>
  );
}

export default App;
