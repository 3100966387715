import React from 'react'
import './Design.css'
const Design = () => {
  return (
    <div className="d-container">
        <img src="./Asset10.png" alt="" />
    </div>
  )
}

export default Design