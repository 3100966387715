import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <section className="f-container">
        <div className="foot">
            <div className="col">
                <span className='head'>What's new</span>
                <span>Microsoft 365</span>
                <span>Games</span>
                <span>Surface Pro 9</span>
                <span>Surface Laptop 5</span>
                <span>Surface Laptop Studio</span>
                <span>Surface Laptop Go 2</span>
                <span>Windows 11 apps</span>
            </div>

            <div className="col">
                <span className='head'>Microsoft Store</span>
                <span>Account profile</span>
                <span>Download Center</span>
                <span>Microsoft Store Support</span>
                <span>Returns</span>
                <span>Order tracking</span>
            </div>

            <div className="col">
                <span className='head'>Education</span>
                <span>Microsoft in education</span>
                <span>Devices for education</span>
                <span>Microsoft Teams for Education</span>
                <span>Microsoft 365 Education</span>
                <span>Office Education</span>
                <span>Educator training and development</span>
                <span>Deals for students and parents</span>
                <span>Azure for students</span>
            </div>

            <div className="col">
            <span className='head'>Business</span>
                <span>Microsoft Cloud</span>
                <span>Microsoft Security</span>
                <span>Azure</span>
                <span>Dynamics 365</span>
                <span>Microsoft 365</span>
                <span>Microsoft Advertising</span>
                <span>Microsoft Industry</span>
                <span>Microsoft Teams</span>
            </div>

            <div className="col">
                <span className='head'>Developer & IT</span>
                <span>Developer Center</span>
                <span>Documentation</span>
                <span>Microsoft Learn</span>
                <span>Microsoft Tech Community</span>
                <span>Azure Marketplace</span>
                <span>AppSource</span>
                <span>Microsoft Power Platform</span>
                <span>Visual Studio</span>
            </div>

            <div className="col last">
                <span className='head'>Company</span>
                <span>Careers</span>
                <span>About Microsoft</span>
                <span>Company news</span>
                <span>Privacy at Microsoft</span>
                <span>Investors</span>
                <span>Security</span>
                <span>Sustainability</span>
            </div>
        </div>
    </section>
  )
}

export default Footer