import React from 'react'
import './Social.css'

const Social = () => {
    return (
        <section className="s-container">
            <div className="social">
                <span>Follow Microsoft</span>
                <img src="./Fb.png" alt="" width={32}/>
                <img src="./twitter.png" alt="" width={32} />
                <img src="./yt.png" alt="" width={32}/>
            </div>
        </section>

    )
}

export default Social